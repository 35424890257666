<template>
  <div class="contact-form">
    <div v-show="!loading">
      <ValidationObserver key="contact" v-slot="{ handleSubmit, valid }">
        <form
          class="contact-form__form"
          @submit.prevent="handleSubmit(handleSendForm)"
        >
          <CustomInput
            v-model="form.name"
            input-name="contact-name"
            input-id="contact-name"
            :input-label="$t('Name')"
            validate-rule="required"
            class="contact-form__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'name' }"
            @inputChange="(value) => inputChange('firstname', value)"
          />
          <CustomInput
            v-model="form.lastName"
            input-name="contact-last-name"
            input-id="contact-last-name"
            :input-label="$t('Last name')"
            validate-rule="required"
            class="contact-form__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'last-name' }"
            @inputChange="(value) => inputChange('lastname', value)"
          />
          <CustomInput
            v-model="form.email"
            input-name="contact-email"
            input-id="contact-email"
            :input-label="$t('Email')"
            validate-rule="required|email"
            class="contact-form__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'email' }"
            @inputChange="(value) => inputChange('email', value)"
          />
          <CustomInput
            v-model="form.repeatEmail"
            input-name="contact-repeat-email"
            input-id="contact-repeat-email"
            :input-label="$t('Repeat Email')"
            validate-rule="required|confirmed:contact-email"
            class="contact-form__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'repeat-email' }"
          />
          <CustomInput
            v-model="form.company"
            input-name="contact-company"
            input-id="contact-company"
            :input-label="$t('Company')"
            class="contact-form__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'company' }"
            @inputChange="(value) => inputChange('company', value)"
          />
          <CustomInput
            v-model="form.phone"
            input-name="contact-phone"
            input-id="contact-phone"
            :input-label="$t('Phone number')"
            validate-rule="numeric"
            class="contact-form__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'phone' }"
            @inputChange="(value) => inputChange('phone', value)"
          />
          <CustomInput
            v-model="form.subject"
            input-name="contact-subject"
            input-id="contact-subject"
            :input-label="$t('Subject')"
            validate-rule="required"
            class="contact-form__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'subject' }"
            @inputChange="(value) => inputChange('subject', value)"
          />
          <CustomTextarea
            v-model="form.message"
            name="contact-message"
            textarea-id="contact-message"
            :label="$t('Message')"
            validate-rule="required|max:1000"
            class="contact-form__form__input"
            :style="{ 'grid-area': 'message' }"
            @onChange="(value) => inputChange('message', value)"
          />
          <div
            class="contact-form__form__input"
            :style="{ 'grid-area': 'checkbox' }"
          >
            <CustomCheckbox
              validate-rule="required"
              @checkboxChange="
                (value) => inputChange('privacy_policy', `${value}`)
              "
            />
          </div>
          <CustomCta
            class="contact-form__form__input"
            :disabled="!valid"
            type="submit"
            theme="dark"
            :is-full-width="!isDesktop"
            :style="{ 'grid-area': 'submit' }"
          >
            {{ $t('Submit') }}
          </CustomCta>
        </form>
      </ValidationObserver>
    </div>
    <LoadingDots v-show="loading" />
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import {
  CustomCta,
  CustomInput,
  CustomCheckbox,
  CustomTextarea,
  LoadingDots,
} from '~/components/General/';
import { ValidationObserver, extend } from 'vee-validate';
import { useWindow, useFormspree, useGtm } from '~/composables';
import { confirmed } from 'vee-validate/dist/rules';

extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your emails match',
});

export default defineComponent({
  name: 'ContactForm',
  components: {
    CustomInput,
    CustomCheckbox,
    ValidationObserver,
    CustomCta,
    CustomTextarea,
    LoadingDots,
  },
  props: {
    formspreeFormId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'contact form',
    },
  },
  setup(props, { emit }) {
    const form = ref({});
    const loading = ref(false);
    const { isDesktop } = useWindow();
    const { sendDataToFormspree, hasSubmitError } = useFormspree(
      props.formspreeFormId
    );
    const { formActionGtmPush } = useGtm();

    const handleSendForm = async () => {
      loading.value = true;
      await sendDataToFormspree(form.value);
      formActionGtmPush(
        `GA4-EVENT-${props.title.toUpperCase()}-HEVELOP`,
        hasSubmitError.value
      );
      emit('onSubmit');
      loading.value = false;
    };

    const inputChange = (field, value) => {
      const inputElement = document.querySelector(
        `#${field}-${props.hubspotFormId}`
      );
      if (props.hubspotFormId && inputElement) {
        inputElement.value = value;
        if (field === 'phone') {
          inputElement.focus();
          document.querySelector(`#message-${props.hubspotFormId}`).focus();
          document.querySelector(`#contact-phone`).focus();
        }
      }
    };

    return {
      form,
      loading,
      isDesktop,
      handleSendForm,
      inputChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.contact-form {
  &__form {
    &__input {
      &:not(:last-child) {
        margin-bottom: 0.625rem;
      }
      &:last-child {
        margin-top: 1.25rem;
      }
    }
  }
  @include from-desktop-min {
    max-width: 64rem;
    &__form {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 0.625rem;
      grid-template-areas:
        'name last-name'
        'email  repeat-email'
        'company phone'
        'subject subject'
        'message message'
        'message message'
        'checkbox attachment'
        'submit .';
    }
  }
}
</style>
